import React, { ReactElement } from 'react'

import { TControlBlock, TCustomBlock, TGroup, TIndicationBlock } from "models/ControlPanel"

import { Group             } from 'components/Blocks/Groups/components/Group'
import { ControlBlock      } from 'components/Blocks/ControlBlocks/components/ControlBlock'
import { IndicationBlock   } from 'components/Blocks/IndicationBlocks/components/IndicationBlock'
import { TargetBlock       } from 'components/Blocks/TargetBlocks/components/TargetBlock'
import { TargetSchedule    } from 'components/Blocks/CustomBlocks/TargetSchedules/components/TargetSchedule'
import { ToogleButtonBlock } from 'components/Blocks/ControlBlocks/components/customControlBlocks/ToogleButtonBlock'
import { ToogleRangeBlock  } from 'components/Blocks/ControlBlocks/components/customControlBlocks/ToogleRangeBlock'
import { CustomTargetBlock } from 'components/Blocks/TargetBlocks/components/customTargetBlocks/CustomTargetBlock'


export const useBlocks: (
   block: TControlBlock | TCustomBlock | TIndicationBlock | TGroup
) => ReactElement = (block) => {

   switch (block.type) {

      case 'UnControlBlock':
      case 'ControlBlock'  :
         return <ControlBlock
            key   = { block.id }
            block = { block }
         />

      case 'TargetBlock':
         return <TargetBlock
            key   = { block.id }
            block = { block }
         />

      case 'CustomTargetBlock':
         return <CustomTargetBlock
            key   = { block.id }
            block = { block }
         />

      case 'TargetSchedule':
         return <TargetSchedule
            key   = { block.id }
            block = { block }
         />

      case 'IndicationBlock':
         return <IndicationBlock
            key   = { block.id }
            block = { block }
         />

      case 'Group':
         return <Group
            key   = { block.id }
            block = { block }
         />

      case 'ToogleRangeBlock':
         return <ToogleRangeBlock
            key   = { block.id }
            block = { block }
         />

      case 'ToogleButtonBlock':
         return <ToogleButtonBlock
            key   = { block.id }
            block = { block }
         />
   }
}