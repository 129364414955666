import { makeAutoObservable } from 'mobx'

import {
   TGroup,
   TControlBlock,
   TCustomBlock,
   TIndicationBlock
} from 'models/ControlPanel'
import { projectStore } from './ProjectsStore'
import { flatAll      } from 'utils/flatAll'
import { TDevice } from 'models/Device'

//import { poolCP } from 'data/poolCP';
//import { heatingCP } from 'data/heatingCP';
//import { ventilationCP } from 'data/ventilationCP';
//import { eshkafCP } from 'data/eshkafCP';
//import { democaseCP } from 'data/democaseCP';

export class ControlPanelStore {

   aliases         : string[] = []
   controlBlocks   : TControlBlock[] = []
   groups          : TGroup[] = []
   targetBlocks    : TCustomBlock[] = []
   targetSchedules : TCustomBlock[] = []
   tagBlocks       : TIndicationBlock[] = []
   indicationBlocks: TIndicationBlock[] = []
   sheduleOptions = []
   loading        = false

   constructor () { makeAutoObservable (this) }

   getState (idProject, idControlPanel) {

      this.loading  = true

      if (projectStore?.projects?.length > 0) {

         const config = projectStore?.projects.find (project => project.id_project === idProject && project.id_controlpanel == idControlPanel)?.config
         let projectConfig //= democaseCP

         if (typeof config === 'string') projectConfig = JSON.parse (config)

         if (projectConfig) {
            //@ts-ignore
            this.aliases          = [...new Set (
               flatAll (projectConfig.outputs)?.map (block => block.alias).filter (alias => alias != undefined).concat (
                  flatAll (projectConfig.inputs )?.map (block => block.alias).filter (alias => alias != undefined))
            )]
            this.tagBlocks        = projectConfig.inputs?.filter  (blocks => blocks.type == 'TagBlock' )
            this.indicationBlocks = projectConfig.inputs?.filter  (blocks => blocks.type == 'IndicationBlock' )
            this.groups           = projectConfig.outputs?.filter (blocks => blocks.type == 'Group' )
            this.controlBlocks    = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'ControlBlock')
            this.targetBlocks     = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'TargetBlock' )
            this.sheduleOptions   = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'ControlBlock')
            this.targetSchedules  = flatAll (projectConfig.outputs)?.filter (blocks => blocks.type == 'TargetSchedule')
         } else this.loading = false
      }

      this.loading = false
   }

   getControlBlockByCommand (command: number) {

      return this.controlBlocks
      .find    (controlBlock => controlBlock.controls
      .flatMap (control => control.options)
      .find    (option => option.command == command))
   }

   getIndicationBlockByCommand (command: number) {

      return this.targetBlocks
      .find    (indicationBlock => indicationBlock.controls
      .flatMap (control => control.options)
      .find    (option => option.command == command))
   }

   getControlByCommand (command: number) {

      return this.controlBlocks
      .flatMap (controlBlock => controlBlock.controls)
      .find    (control => control.options
      .find    (option => option.command == command))
   }

   getIndicationByCommand (command: number) {

      return this.targetBlocks
      .flatMap (indicationBlock => indicationBlock.controls)
      .find    (control => control.options
      .find    (option => option.command == command))
   }

   getControlOptionByCommand (command: number) {

      return this.controlBlocks
      .flatMap (controlBlock => controlBlock.controls)
      .flatMap (control => control.options)
      .find    (option => option.command == command)
   }

   getIndicationOptionByCommand (command: number) {

      return this.targetBlocks
      .flatMap (indicationBlock => indicationBlock.controls)
      .flatMap (control => control.options)
      .find    (option => option.command == command)
   }
}

export const controlPanelStore = new ControlPanelStore ()